import 'web/lib/string-utils'
import classNames from 'classnames'
import useBloodBalance from 'web/hooks/useBloodBalance'
import Link from 'next/link'
import { useContext, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import useEnvironment from 'web/hooks/useEnvironment'
import useWalletTokens from 'web/hooks/useWalletTokens'
import { setTag } from '@sentry/nextjs'
import { Storage } from 'web/lib/storage'

const WalletNavButton = () => {
  const { active, account } = useWeb3React()
  const bloodBalance = useBloodBalance()
  const { isBeta } = useEnvironment()

  const ownedTokens = useWalletTokens()

  // If wallet connected, set the cookie
  useEffect(() => {
    if (active && account) {
      localStorage.setItem('wallet_connected', 'true')

      setTag('wallet.type', Storage.getWallet())
    }
  }, [active])

  const balance = bloodBalance > 100 ? Number(bloodBalance.toFixed(0)).toLocaleString() : bloodBalance.toFixed(2)

  return (
    <Link href="/wallet">
      <a className={classNames('primaryButton order-1 space-x-2 w-full md:w-auto text-center whitespace-nowrap')}>
        <span className="text-black">{ownedTokens.length} ▲</span>

        {isBeta &&
          <>
            <span className="hidden md:inline">{balance}</span>
            <span className="inline md:hidden">{balance}</span>
            <span className="text-black text-sm md:text-base">$BLOOD</span>
          </>
        ||
          <span>My Gawds</span>
        }
      </a>
    </Link>
  )
}

export default WalletNavButton
