import { useWeb3React } from '@web3-react/core'
import { useEffect, useMemo } from 'react'
import { gawdsContractAddress, gawdsContractABI } from 'web/lib/contract'
import { Gawds, Gawds__factory } from 'web/types/ethers-contracts'

export default function useGawdsContract(withSigner = false): Gawds {
  const { library, active, account, chainId } = useWeb3React()

  return useMemo(() => {
    if (!library && !active) return null
    const lib = withSigner ? library.getSigner(account).connectUnchecked() : library
    return Gawds__factory.connect(gawdsContractAddress, lib)
  }, [library, active, account, chainId])
}
