import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { CacheKey } from "web/lib/cache-keys";
import { ContractData } from "web/lib/contract";
import useGawdsContract from "./useGawdsContract";
import useTargetChain from "./useTargetChain";

export default function useGawdsContractData(initContractData: ContractData) {
    const { chainId, library, account } = useWeb3React()
    const contract = useGawdsContract()
    const { isOnValidChain } = useTargetChain()

    const isReady = isOnValidChain && contract

    const { data: price } = useSWR(isReady ? [chainId, `/price`] : null, async (): Promise<BigNumber> => {
        return await contract.calculatePrice()
    }, { onSuccess: () => {} })

    const { data: maxSupply } = useSWR(isReady ? [chainId, `/maxSupply`] : null, async (): Promise<number> => {
        return (await contract.maxSupply()).toNumber()
    }, { onSuccess: () => {} })

    const { data: totalSupply, mutate: mutateSupply } = useSWR(isReady ?  CacheKey.totalGawdSupply(chainId) : null, async (): Promise<number> => {
        return (await contract.totalSupply()).toNumber()
    }, {
        refreshInterval: 1000 * 60 * 10
    })

    const { data: saleState, mutate: mutateSaleState } = useSWR(isReady ?  [chainId, `/saleState`] : null, async (): Promise<boolean> => {
        return await contract.saleStarted()
    })

    const [block, setBlock] = useState<number>(initContractData.block)
    function onNewBlock(newBlock) {
        setBlock(newBlock)
    }

    useEffect(() => {
        if (!contract) return

        if (library) {
            library.once('block', onNewBlock)
        }

        return () => {
            library.off('block', onNewBlock)
        }
    }, [contract, library])

    // Init with contract data
    const _soldSupply = totalSupply || initContractData.totalSupply
    const _maxSupply = maxSupply || initContractData.maxSupply
    const _isSalesEnabled = saleState !== undefined ? saleState : initContractData.saleStarted

    return {
        block: block,
        price: price || ethers.utils.parseEther('0.08'),
        maxSupply: _maxSupply,
        soldSupply: _soldSupply,
        remainingSupply: _maxSupply && _soldSupply ? _maxSupply - _soldSupply : 0,
        isSalesEnabled: _isSalesEnabled,
        isSoldOut: !_isSalesEnabled || _soldSupply >= _maxSupply,
        mutateSaleState,
        mutateSupply
    }
}