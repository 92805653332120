import { useWeb3React } from "@web3-react/core"
import classNames from "classnames"
import { useCallback } from "react"
import { Storage } from "web/lib/storage"

export function DisconnectWalletButton({ className = '' }) {
  const { deactivate } = useWeb3React()

  const logout = useCallback(() => {
    Storage.setWallet(null)
    localStorage.removeItem('wallet_connected')
    deactivate()
  }, [deactivate])


  return <button
    className={classNames(className)}
    onClick={logout}
  >
    Disconnect
  </button>
}
