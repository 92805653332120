import 'web/lib/string-utils'
import { StoreContext } from 'web/lib/store'
import { useContext } from 'react'
import styles from './PolygonTxnToasts.module.css'
import classNames from 'classnames'
import TxnToast from './TxnToast'

const PolygonTxnToasts = () => {
  const {
    polygonTxns: [polygon, dispatchPolgyonTxns],
  } = useContext(StoreContext)

  return (
    <div id="polygon-txns" className={classNames(styles.container)}>
      {polygon && polygon.txns.map((txn) => txn.command && (
        <TxnToast key={txn.nonce || 'unknown'} txn={txn} />
      ))}
    </div>
  )
}

export default PolygonTxnToasts
