import classNames from "classnames"
import Link from "next/link"
import useENSName from "web/hooks/useENSName"
import { formatEtherscanLink, LinkProps } from "web/lib/chain-utils"

type Props = LinkProps & {
    className?: string
    children?: any
}

export function EthLink({ type, chainId, hash, className, children }: Props) {
    let ensName = null
    // Use ENS name for eth accounts
    if (type == 'account' && Number(chainId) == 1) {
      ensName = useENSName(hash)
    }

    return hash ? <Link href={formatEtherscanLink({
      type: type,
      chainId: chainId,
      hash: hash
    })}>
      <a className={classNames("text-red-500 no-underline hover:underline", className)} target={'_blank'}>
        {ensName || hash.prettyHex()}
        {children}
      </a>
    </Link> : <>...</>
  }