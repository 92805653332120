import { useWeb3React } from '@web3-react/core'
import WalletNavButton from 'web/components/WalletNavButton'
import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { ExternalLinkIcon } from '@heroicons/react/solid'
import { DisconnectWalletButton } from 'web/components/DisconnectWalletButton'
import { EthLink } from './EthLink'

export default function MyGawdsMenu() {
  const { chainId, account } = useWeb3React()

  return (
    <div className="text-left flex flex-row-reverse space-x-2 items-center">
      <WalletNavButton />

      <Menu as="div" className="relative z-0">
        <Menu.Button className="select-none bg-red-500 bg-opacity-30 z-0 text-red-500 py-2 px-2.5 rounded-md hover:bg-red-300 active:bg-red-900">
          <span className="sr-only">Options</span>
          <ChevronDownIcon className="h-5 w-5 my-0.5" aria-hidden="true" />
        </Menu.Button>

        <Menu.Items className="flex flex-col bg-white text-black rounded-md origin-top-right absolute right-0 mt-2 w-60">
          <Menu.Item>
            <EthLink type='account' chainId={chainId} hash={account}
              className='flex items-center px-3 py-2 text-base no-underline hover:text-white hover:bg-red-500 transition-all rounded-t-md text-black hover:no-underline'>
              <ExternalLinkIcon className="ml-1 h-4 w-4" aria-hidden="true" />
            </EthLink>
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <DisconnectWalletButton className="bg-white hover:text-white hover:bg-red-500 transition-all flex px-3 py-2 text-base rounded-b-md" />
            )}
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </div>
  )
}
