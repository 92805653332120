import { formatUnits } from '@ethersproject/units'

export function shortenHex(hex, length = 4) {
  return `${hex.substring(0, length + 2)}…${hex.substring(hex.length - length)}`
}

export const INFURA_PREFIXES = {
  1: 'mainnet',
  3: 'ropsten',
  4: 'rinkeby',
  5: 'goerli',
  42: 'kovan',
  137: 'polygon-mumbai',
  80001: 'polygon-mainnet',
  31337: 'localhost'
}

export const ETHERSCAN_URLS = {
  1: "etherscan.io",
  3: "ropsten.etherscan.io",
  4: "rinkeby.etherscan.io",
  5: "goerli.etherscan.io",
  42: "kovan.etherscan.io",
  137: "polygonscan.com",
  80001: "mumbai.polygonscan.com",
  31337: "localhost"
}

export function chainNameFor(chainId) {
  return INFURA_PREFIXES[chainId];
}

export type LinkType = 'account' | 'transaction'
export type LinkProps = {
  type: LinkType
  chainId: number | string
  hash: string
}

export function formatEtherscanLink(data: LinkProps) {
  switch (data.type) {
    case 'account':
      return `https://${ETHERSCAN_URLS[Number(data.chainId)]}/address/${data.hash}`

    case 'transaction':
      return `https://${ETHERSCAN_URLS[Number(data.chainId)]}/tx/${data.hash}`
  }
}