import { Fragment, useContext } from 'react'
import classNames from 'classnames'
import getConfig from 'next/config'
import Link from 'next/link'
import { Menu, Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { useWeb3React } from '@web3-react/core'

import MyGawdsMenu from 'web/components/MyGawdsMenu'
import { formatEtherscanLink } from 'web/lib/chain-utils'

import styles from './Navigation.module.css'
import ConnectWalletButton from './ConnectWalletButton'
import useEnvironment from 'web/hooks/useEnvironment'
import { StoreContext, TransactionStatus } from 'web/lib/store'

// Only holds serverRuntimeConfig and publicRuntimeConfig
const {
  publicRuntimeConfig: { openseaUrl, discordInviteUrl, docsUrl },
} = getConfig()

export default function Navigation({ }) {
  const { account, library, chainId } = useWeb3React()
  const isConnected = typeof account === 'string' && !!library

  const { isBeta } = useEnvironment()

  const {
    transaction: [transactionStatus],
  } = useContext(StoreContext)

  const navigation = [
    { name: 'Home', href: '/', visible: true },
    { name: 'Gallery', href: '/gallery/page/1', visible: true },
    { name: 'Docs', href: docsUrl, target: '_blank', visible: true },
    { name: 'Discord', href: discordInviteUrl, target: '_blank', visible: !isBeta },
    {
      name: '$Blood',
      href: '#blood',
      visible: isBeta,
      dropdown: [
        { name: 'Sanctuary', href: '/wallet' },
        { name: 'Exchange', href: '/exchange' },
        { name: 'Transfer', href: '/transfer' },
        { name: 'Cults', href: '/cults' },
      ]
    },
    {
      name: 'Links',
      href: '#',
      visible: true,
      dropdown: [
        { name: 'Discord', href: discordInviteUrl, target: '_blank', visible: isBeta },
        { name: 'OpenSea', href: openseaUrl, target: '_blank' },
        { name: 'Contracts', href: '/contracts' },
        { name: 'Rarity.tools', href: 'https://rarity.tools/gawds/', target: '_blank' },
      ],
    },
  ]

  return (
    <nav className={classNames(
        "absolute inset-x-0 mx-auto max-w-7xl flex flex-row z-10 mt-4 transition-opacity duration-1000",
        transactionStatus.status == TransactionStatus.Started ? "opacity-30" : "opacity-100"
      )}>
      {/* NOTE: Popover is strictly for the mobile navigation. The tablet/desktop equivalent is below this component */}
      <Menu>
        {({ open }) => (
          <div className={classNames('md:hidden', { 'bg-black': open })}>
            <Menu.Button className="ml-4 mb-2 p-2 pr-3 rounded-md bg-gray-900 hover:bg-gray-700 whitespace-nowrap transition-all">
              <span className="sr-only">Main menu</span>
              {open ? (
                <XIcon className="h-6 w-6 inline" aria-hidden="true" />
              ) : (
                <MenuIcon className="h-6 w-6 inline" aria-hidden="true" />
              )}
              <span className="ml-2 inline-block align-middle">Menu</span>
            </Menu.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-100"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-100"
            >
              <Menu.Items
                key="items"
                static
                className="absolute inset-x-0 top-[-16px] pb-2 pt-0 text-center bg-gray-900 flex flex-col md:flex-row md:flex-1 md:space-x-4 z-20"
              >
                <Menu.Item key="menu" as="div" className="text-left">
                  <Menu.Button className="ml-4 mt-4 p-2 rounded-md0">
                    <span className="sr-only">Main menu</span>
                    {open ? (
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    )}
                  </Menu.Button>
                </Menu.Item>
                {navigation.map((item) => {
                  if (!item.visible) return null

                  if ('dropdown' in item) {
                    return item.dropdown.map((dropdownItem) => dropdownItem.visible !== false && (
                      <MobileLink key={dropdownItem.name} item={dropdownItem} />
                    ))
                  }
                  else {
                    return <MobileLink key={item.name} item={item} />
                  }
                })}
              </Menu.Items>
            </Transition>
          </div>
        )}
      </Menu>

      {/* NON-MOBILE NAV */}
      <div className="md:grid md:grid-cols-1 items-baseline">
        <div className="hidden md:flex md:flex-row w-full pl-4">
          <Popover.Group className={'space-x-0 lg:space-x-4'}>
            {navigation.map((item) => {
              if (!item.visible) return

              if ('dropdown' in item) {
                return (
                  <Popover as="div" className="relative inline-block" key={item.href}>
                    <Popover.Button className={`${classNames('inline-block', styles.navLink)}`}>
                      <>
                        {item.name}
                        <ChevronDownIcon
                          className={classNames(
                            'transform transition-transform duration-200 ml-2 -mt-1 h-4 w-4 inline text-gray-400'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    </Popover.Button>
                    <Popover.Panel className="absolute bg-black rounded-lg px-2 -left-2 mt-0.5">
                      {item.dropdown.map((dropdownItem) => {
                        if (dropdownItem.visible === false) return
                        return (
                          <Link href={dropdownItem.href} key={dropdownItem.name}>
                            <a
                              target={dropdownItem.target}
                              className={`${classNames('block', styles.navLink)}`}
                            >
                              <span>{dropdownItem.name}</span>
                            </a>
                          </Link>
                        )
                      })}
                    </Popover.Panel>
                  </Popover>
                )
              } else {
                return (
                  <Link href={item.href} key={item.name}>
                    <a target={item.target} className={`${classNames('inline-block', styles.navLink)}`}>
                      <span>{item.name}</span>
                    </a>
                  </Link>
                )
              }
            })}
          </Popover.Group>
        </div>

      </div>

      <div className="text-right flex-1 mx-4 md:m-0 md:mr-4">
        {!isConnected && <ConnectWalletButton className="w-full md:w-auto" />}
        {isConnected && <MyGawdsMenu />}
      </div>
    </nav>
  )
}

function MobileLink({ key, item }) {
  return <Menu.Item key={key}>
    <Link href={item.href}>
      <a
        target={item.target}
        className={classNames("block text-gray-300 no-underline p-4 mx-2 md:py-2 transition-all hover:bg-gray-700 active:bg-red-500 rounded-xl")}
      >
        <span>{item.name}</span>
      </a>
    </Link>
  </Menu.Item>
}
