import { useWeb3React } from '@web3-react/core'
import useSWR from 'swr'

export default function useENSName(address: string): string | null {
  const { library, chainId } = useWeb3React()

  const { data: ensName } = useSWR(library && chainId == 1 ? ['/ensName', address, chainId] : null, async () => {
    const name = await library.lookupAddress(address)
    return name
  }, {
    onError: () => {},
    errorRetryCount: 0
  })

  return ensName || null
}