// **server-side contract interactions only**
// for the frontend we are relying solely on a client-side web3 provider
// in the backend we're using a read-only connection to either localhost or to Alchemy (for rinkeby/mainnet)
import { BigNumber, ethers } from 'ethers'
import { chainNameFor } from 'web/lib/chain-utils'
import { Web3Provider } from '@ethersproject/providers'
import { BaseContract } from 'ethers'

import * as Factories from 'web/types/ethers-contracts/factories'
import { Gawds } from 'web/types/ethers-contracts'

import polygonMumbaiAddresses from '../addresses/80001.json'
import polygonLocalAddresses from '../addresses/31337.json'
import polygonMainnetAddresses from '../addresses/137.json'
let polygonAddresses: typeof polygonMainnetAddresses

// A bunch of helper vars
// FIXME these are duplicating data in lib/chain-utils
export const polygonMumbaiChainId = 80001
export const polygonMainnetChainId = 137
export const polygonLocalChainId = 31337
export const polygonChainId: number = process.env.POLYGON_CHAIN_ID && Number(process.env.POLYGON_CHAIN_ID)
export const isPolygonMainnet: boolean = polygonChainId == polygonMainnetChainId
export const isPolygonTestnet: boolean = polygonChainId == polygonMumbaiChainId
export const isPolygonLocal: boolean = polygonChainId == polygonLocalChainId
export const polygonNetworkName: string = isPolygonMainnet ? 'Mainnet' : isPolygonTestnet ? 'Testnet' : 'Local'

if (isPolygonMainnet) {
  // polygon mainnet
  polygonAddresses = polygonMainnetAddresses
} else if (isPolygonTestnet) {
  // mumbai testnet
  polygonAddresses = polygonMumbaiAddresses
} else if (polygonChainId == polygonLocalChainId) {
  // localhost
  polygonAddresses = polygonLocalAddresses
} else {
  console.log({ polygonChainId, isPolygonMainnet, isPolygonTestnet })
  throw new Error('Missing polygonAddresses')
}

export const PolygonAddresses = polygonAddresses
export const PolygonMumbaiAddresses = polygonMumbaiAddresses
export const PolygonLocalAddresses = polygonLocalAddresses
export const PolygonMainnetAddresses = polygonMainnetAddresses

// on Ethereum
import IGawdsContract from 'web/abis/Gawds.json'
export const gawdsContractAddress = process.env.CONTRACT_ADDRESS
export const gawdsContractABI = IGawdsContract.abi

let cachedContract: Gawds = null
let cachedProvider = []

export function getGawdsContract(): Gawds {
  if (!gawdsContractAddress) {
    console.warn("No gawdsContractAddress, can't load contract")
    return null
  }

  if (cachedContract) {
    return cachedContract
  }

  const chainId = process.env.CHAIN_ID
  const chainName = chainNameFor(chainId)

  console.log('contract(): Connecting to network', chainId, chainName)

  cachedContract = Factories.Gawds__factory.connect(gawdsContractAddress, provider())

  return cachedContract
}

export type ContractMap = typeof polygonAddresses
export type ContractType = keyof ContractMap
export class GawdsContractFactory {
  static cache: any = {}

  static get<K extends ContractType>(contractType: K): BaseContract {
    if (this.cache[contractType]) {
      return this.cache[contractType]
    }

    for (const factory of Object.values(Factories)) {
      const contractName = new factory().contractName
      if (contractName == contractType) {
        this.cache[contractType] = factory.connect(polygonAddresses[contractType], getPolygonProvider())
        return this.cache[contractType]
      }
    }

    throw new Error(`Factory not found for ${contractType}`)
  }
}

// TODO refactor to getProvider()
let polygonProvider = null
export const getPolygonProvider = (): Web3Provider => {
  if (polygonProvider) {
    return polygonProvider
  }

  // console.log("setting up polygonProvider POLYGON_RPC =>", process.env.POLYGON_RPC)
  polygonProvider = new ethers.providers.StaticJsonRpcProvider(process.env.POLYGON_RPC)
  return polygonProvider
}

export function provider(): Web3Provider {
  let chainName = chainNameFor(process.env.CHAIN_ID)

  if (cachedProvider[chainName]) {
    return cachedProvider[chainName]
  }

  const isServerSide = typeof window === 'undefined'

  let provider
  if ((!chainName || chainName == 'localhost') && isServerSide) {
    console.log('provider(): using local JsonRpcProvider')
    provider = new ethers.providers.StaticJsonRpcProvider('http://127.0.0.1:8545/')
  } else if (isServerSide) {
    // TODO should make this ETHEREUM_RPC or ETH_RPC_URL
    provider = new ethers.providers.AlchemyProvider(chainName, process.env.ALCHEMY_API_KEY)
    console.log(`provider(): AlchemyProvider for ${chainName} => ${provider.connection.url}`)
  }

  cachedProvider[chainName] = provider
  return cachedProvider[chainName]
}

export function isContractAvailable() {
  // console.log('contractAvailable()', await checkContract(gawdsContractAddress))
  // await checkContract(gawdsContractAddress)
  return !!gawdsContractAddress
}

export async function checkContract() {
  const byteCode = await provider().getCode(gawdsContractAddress)
  console.log('checkContract() byteCode.length =>', byteCode.length)
  if (byteCode.length > 2) {
    return true
  } else {
    return false
  }
}

export interface ContractData {
  chainId: number
  saleStarted: boolean
  maxSupply: number
  totalSupply: number
  price: number
  block: number
  isSoldOut: boolean
  isLive: boolean
}

export async function getContractData(): Promise<ContractData> {
  // TODO couldn't these be executed in parallel? Promise.all style
  const saleStarted = await getSaleStarted()
  const maxSupply = await getMaxSupply()
  const totalSupply = await getTotalSupply()

  return {
    chainId: parseInt(process.env.CHAIN_ID),
    saleStarted: saleStarted,
    maxSupply: maxSupply.toNumber(),
    totalSupply: totalSupply.toNumber(),
    price: parseFloat(ethers.utils.formatEther(await getPrice())),
    block: await getCurrentBlock(),
    isSoldOut: maxSupply == totalSupply,
    isLive: getIsLive(saleStarted, totalSupply),
    // currentGasPrice: (await getGasPrice()).toString(),
  }
}

export function getIsLive(saleStarted, totalSupply) {
  const premintSupply = parseInt(process.env.PREMINT_SUPPLY_TOTAL) || 400
  return saleStarted || totalSupply - premintSupply > 0
}

export async function getCurrentBlock() {
  if (!isContractAvailable()) return 0
  return await provider().getBlockNumber()
}

export async function getGasPrice() {
  if (!isContractAvailable()) return BigNumber.from(0)
  return await provider().getGasPrice()
}

export async function getTotalSupply() {
  if (!isContractAvailable()) return BigNumber.from(0)
  return await getGawdsContract().totalSupply()
}

export async function getPrice(): Promise<BigNumber> {
  if (!isContractAvailable()) return BigNumber.from(0)
  return await getGawdsContract().calculatePrice()
}

export async function getMaxSupply() {
  if (!isContractAvailable()) return BigNumber.from(0)
  return await getGawdsContract().maxSupply()
}

export async function getTokenURI(id) {
  if (!isContractAvailable()) return null
  return await getGawdsContract().tokenURI(id)
}

export async function getSaleStarted() {
  if (!isContractAvailable()) return false
  return await getGawdsContract().saleStarted()
}
