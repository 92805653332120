import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";
import { useContext, useEffect } from "react";
import { useSWRConfig } from "swr";
import { CacheKey } from "web/lib/cache-keys";
import { ContractData } from "web/lib/contract";
import { DebugContext } from "web/lib/debug-provider";
import { StoreAction, StoreContext, TransactionStatus } from "web/lib/store";
import useEnvironment from "./useEnvironment";
import useGawdsContract from "./useGawdsContract";
import useGawdsContractData from "./useGawdsContractData";

export default function useBindGawdContractListeners(initContractData: ContractData) {
    const { isDev } = useEnvironment()
    const { chainId, library, account } = useWeb3React()
    const contract = useGawdsContract()
    const { mutate } = useSWRConfig()
    const { block, mutateSaleState, mutateSupply } = useGawdsContractData(initContractData)

    const {
        transaction: [transactionStatus, dispatchTransactionStatus],
    } = useContext(StoreContext)
    const [ debug, setDebugMessage ] = useContext(DebugContext)

    function onContractTransfer(from: string, to: string, tokenId: BigNumber, rawEvent: any) {
        if (rawEvent.blockNumber <= block) return // ignore events from current block (on load)
        if (isDev) console.log('onContractTransfer', from, to, tokenId, rawEvent)

        setDebugMessage([StoreAction.Add, `${block}: minted Gawd ${tokenId} to ${to}`])
        mutateSupply()

        // Gawd was minted. If a transaction is pending, complete it
        if (to == account && transactionStatus.status == TransactionStatus.Pending) {
            dispatchTransactionStatus([StoreAction.Update, {
                status: TransactionStatus.Complete,
            }])
            mutate(CacheKey.tokenCount(account, chainId))
        }
    }

    function onSaleState(activated, rawEvent)  {
        if (isDev) console.log('onSaleState', activated, rawEvent.blockNumber, rawEvent)
        mutateSupply()
        mutateSaleState()
    }

    useEffect(() => {
        if (!contract) return

        contract.once('Transfer', onContractTransfer)
        contract.once('SaleState', onSaleState)

        return () => {
            contract.off('Transfer', onContractTransfer)
            contract.off('SaleState', onSaleState)
        }
    }, [contract, library])

}