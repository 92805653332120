import { Wallet } from "ethers";
import { WalletType } from "web/components/WalletProviderButton";

export class Storage {
  public static getWallet(): WalletType | null {
    try {
      const wallet = localStorage.getItem('wallet')
      if (!wallet) return null

      switch (localStorage.getItem('wallet').toLowerCase()) {
        case 'metamask':
          return WalletType.MetaMask
        case 'coinbase':
          return WalletType.Coinbase
        case 'walletconnect':
          return WalletType.WalletConnect
      }
    } catch (e) {

    }

    return null
  }

  public static setWallet(type: WalletType) {
    if (type == null) {
      localStorage.removeItem('wallet')
    }
    else {
      localStorage.setItem('wallet', type)
    }
  }

  public static resetWallet() {
    this.setWallet(null)
  }
}