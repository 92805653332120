import classNames from "classnames"
import usePortalShutdown from "web/hooks/usePortalShutdown"
import { ContractData } from "web/lib/contract"
import Banner from "./Banner"

interface ShutdownBannerProps {
    hide?: boolean
    initContractData: ContractData
}

export default function ShutdownBanner({ hide = false, initContractData }: ShutdownBannerProps) {
    const { getTimeRemaining, getLostGawdsTotal, remainingSupply } = usePortalShutdown(initContractData)

    const bubbleItems = [
        // {
        //     title: "Lost Gawds",
        //     value: Number(getLostGawdsTotal()).toLocaleString(),
        //     mobile: false
        // },
        // {
        //     title: "Remaining Supply",
        //     value: Number(remainingSupply).toLocaleString(),
        //     mobile: false,
        // },
        {
            title: "Est. Mint Close",
            value: getTimeRemaining().timeForHumans('short'),
            mobile: true,
        },
    ]

    if (!remainingSupply || remainingSupply <= 0 || getLostGawdsTotal() == 0) {
        return <></>
    }

    return (
        <Banner link="https://chad.mirror.xyz/5JHjjjs6isGr8_0do-Zd_6j1pmOFFvMP1-JaQCufZjU" target="_blank" className="bg-green-300">
            <div className="flex flex-row justify-end md:py-4">
                {bubbleItems.map((item, i) =>
                    <div key={i}
                        className={classNames(
                            item.mobile ? "flex" : "hidden md:flex",
                            "space-x-2 content-center sm:pl-4"
                        )}>
                        <div className="bg-black bg-opacity-60 p-2 md:px-4 md:py-2 space-y-0 rounded-xl text flex flex-col justify-center">
                            <div className="text-xs">
                                {item.title}
                            </div>
                            <div className="font-serif text-2xl text-center" suppressHydrationWarning>
                                {item.value}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex-1 flex flex-col lg:flex-none md:w-2/5 space-y-1 text-black">
                <div className="text-sm md:text-xl font-bold font-serif">
                    50% off all remaining Gawds
                </div>
                <div className="text-xs md:text-sm relative z-[10]">

                    {/* 100+ unminted Gawds are being removed from the total supply every day. This is your last chance to summon a Gawd. */}

                    Pay 0.08 ETH now and we will pay back 0.04 after the mint completes.
                    {' '}
                    <a href="https://chad.mirror.xyz/5JHjjjs6isGr8_0do-Zd_6j1pmOFFvMP1-JaQCufZjU" target={'_blank'}>
                        Learn more
                    </a>
                </div>
            </div>
        </Banner>
    )
}