import { NetworkConnector } from '@web3-react/network-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { AbstractConnector } from '@web3-react/abstract-connector';
import { INFURA_PREFIXES } from 'web/lib/chain-utils'
import { WalletType } from './components/WalletProviderButton';

export function getNetwork(defaultChainId = 1) {
  return new NetworkConnector({
    urls: [1, 3, 4, 5, 42].reduce(
      (urls, chainId) =>
        Object.assign(urls, {
          [chainId]: `https://${INFURA_PREFIXES[chainId]}.infura.io/v3/${process.env.INFURA_PROJECT_ID}`,
        }),
      {}
    ),
    defaultChainId,
  })
}

export function getConnector(walletType: WalletType): AbstractConnector {
  switch (walletType) {
    case WalletType.MetaMask:
      return Injected

    case WalletType.Coinbase:
      return CoinbaseWallet

    case WalletType.WalletConnect:
      return WalletConnect

    default:
      return null
  }
}

export const WalletConnect = new WalletConnectConnector({
  // infuraId: process.env.INFURA_PROJECT_ID,
  supportedChainIds: [1, 4],
  rpc: {
    1: `https://${INFURA_PREFIXES[1]}.infura.io/v3/${process.env.INFURA_PROJECT_ID}`,
    4: `https://${INFURA_PREFIXES[4]}.infura.io/v3/${process.env.INFURA_PROJECT_ID}`,
  },
  // bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
})

export const CoinbaseWallet = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${process.env.INFURA_PROJECT_ID}`,
  appName: "Gawds",
  supportedChainIds: [1, 3, 4, 5, 42],
 });

// AKA metamask
export const Injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 31337],
})