import { useCallback, useMemo } from "react"
import useSWR from "swr"
import { MathUtils } from "three"
import { ContractData } from "web/lib/contract"
import useGawdsContractData from "./useGawdsContractData"

export default function usePortalShutdown(initContractData: ContractData) {
    let gawdData = useGawdsContractData(initContractData)

    const { startTime, shutdownTime, initialMintedGawdsTotal, lostGawdsTotal } = useMemo(() => {
        return {
            startTime: Date.parse("2022-05-20T20:58:00-0400"),
            shutdownTime: Date.parse("2022-05-24T14:00:00-0400"),
            // this number should be hardcoded to the total gawds minted upon launch of The Shutdown
            initialMintedGawdsTotal: initContractData.chainId == 1 ? 5867 : 100,

            // Update later on in the shutdown with latest total lost gawds to adjust the rate
            // Also requires updating the startTime & initialMintedGawdsTotal
            lostGawdsTotal: 4120,
        }
    }, [initContractData.chainId])

    const floor = useMemo(() => 147, [])

    const getTimeRemaining = useCallback((): number => {
        return (shutdownTime - (new Date()).getTime()) / 1000
    }, [])

    const getElapsedTime = useCallback((): number => {
        return ((new Date()).getTime() - startTime) / 1000
    }, [])

    const getLostGawdsTotal = useCallback((): number => {
        const totalTime = (shutdownTime - startTime) / 1000
        const totalHours = totalTime / 60 / 60
        const gawdsLostPerHour = (gawdData.maxSupply - initialMintedGawdsTotal - lostGawdsTotal) / totalHours
        let lost = Math.floor(getElapsedTime() / 60 / 60 * gawdsLostPerHour) + lostGawdsTotal
        // Make sure there are always XXX gawds available
        // if (gawdData.soldSupply + lost + floor >= 10000) {
        //     const finalTotalHours = (Date.parse("2022-05-19T23:59:00-0400") - startTime) / 1000 / 60 / 60
        //     // Coutndown from floor to shutdownTime
        //     const lostPerHour = floor / finalTotalHours
        //     const finalLost = Math.floor(getElapsedTime() / 60 / 60 * lostPerHour)
        //     console.log({ lostPerHour, finalLost, floor })
        //     lost = 10000 - gawdData.soldSupply - (floor - finalLost)
        // }

        return MathUtils.clamp(lost, 0, gawdData.maxSupply)
    }, [gawdData.maxSupply, gawdData.soldSupply, floor])

    const getMaxSupply = useCallback((): number => {
        return gawdData.maxSupply - getLostGawdsTotal()
    }, [gawdData.maxSupply])

    function getRemainingSupply():number {
        return MathUtils.clamp(getMaxSupply() - gawdData.soldSupply, 0, getMaxSupply())
    }
    const { data: remainingSupply } = useSWR('/portal/remainingSupply', (): number => {
        return getRemainingSupply()
    }, {
        refreshInterval: 5000,
        dedupingInterval: 0,
        onSuccess: () => {}
    })
    const supply = remainingSupply || getRemainingSupply()

    return {
        shutdownTime,
        getTimeRemaining,
        getElapsedTime,
        getLostGawdsTotal,
        getMaxSupply,
        remainingSupply,
        canPurchase: supply > 0 && !gawdData.isSoldOut
    }
}