import { useWeb3React } from '@web3-react/core'
import { getConnector, WalletConnect } from 'web/connectors';
import { Storage } from 'web/lib/storage';
import { isMobile } from 'react-device-detect';

export enum WalletType {
  MetaMask = 'MetaMask',
  Coinbase = 'Coinbase',
  WalletConnect = 'WalletConnect'
}

export type WalletButtonProps = {
  wallet: WalletType
}

export default function WalletProviderButton({ wallet }: WalletButtonProps) {
  const { active, error, activate, setError, deactivate, account } = useWeb3React()

  function onConnectClick() {
    // Dumb hack to get metamask to work on mobile... tmp fix?
    // if (isMobile && wallet == WalletType.MetaMask) {
    //   wallet = WalletType.WalletConnect
    // }

    Storage.setWallet(wallet)

    // Force reset the walletconnector (fixes issue with getting it show up a second time)
    if (wallet == WalletType.WalletConnect) {
      WalletConnect.close()
      WalletConnect.walletConnectProvider = null
    }

    const connector = getConnector(wallet)
    activate(connector)
  }

  return (
    <button onClick={onConnectClick} className="connectWalletButton">
      {wallet == WalletType.Coinbase && <>
        Coinbase Wallet
        <img src="/images/coinbase-wallet.png" className="h-10 w-10" aria-hidden="true" />
      </>}

      {wallet == WalletType.MetaMask && <>
        MetaMask
        <img src="/images/metamask-fox.svg" className="h-10 w-10" aria-hidden="true" />
      </>}

      {wallet == WalletType.WalletConnect && <>
        WalletConnect
        <img src="/images/walletconnect-logo.svg" className="h-10 w-10" aria-hidden="true" />
      </>}
    </button>
  )
}
