import { GawdsContractFactory, getPolygonProvider } from 'web/lib/contract'
import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import useSWR from 'swr'
import { CacheKey } from 'web/lib/cache-keys'
import { Blood } from 'web/types/ethers-contracts'

export default function useBloodBalance(): number {
  const { account, active } = useWeb3React()

  const energyContract: Blood = GawdsContractFactory.get("Blood") as Blood

  const { data } = useSWR(CacheKey.bloodBalance(account), async (): Promise<number> => {
    if (!energyContract || !active) return null
    const balance = await energyContract.balanceOf(account)
    return parseInt(ethers.utils.formatEther(balance))
  }, {
    refreshInterval: 1000 * 60 * 30, // 30 minutes
  })

  return data || 0
}
