import classNames from 'classnames'
import Link from 'next/link'

const Banner = ({ children, target = '_self', className = "", link = "" }) => {
  return (
    <div className={classNames("bg-red-500", className)}>
      <Link href={link} target={target}>
        <a href={link} target={target} className="no-underline">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-center space-x-4 md:space-x-6">
              {children}
            </div>
          </div>
        </a>
      </Link>
    </div>
  )
}

export default Banner