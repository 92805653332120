// TODO rename this file to general `utils.ts` or somethin
export { }

declare global {
  interface String {
    toGatewayUrl(): string;
    ucfirst(): string;
    prettyHex(): string;
  }
}

String.prototype.toGatewayUrl = function () {
  const root = `${process.env.IPFS_SERVER}/ipfs/`
  return this.toString().replace('https://ipfs.io/ipfs/', root).replace('ipfs://', root)
}

String.prototype.ucfirst = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.prettyHex = function(length = 4) {
  return `${this.substring(0, length + 2)}…${this.substring(this.length - length)}`
}

type TimeMode = "long" | "short"

declare global {
  interface Number {
    toDays(): number;
    toHours(): number;
    toMinutes(): number;
    toSeconds(): number;
    timeForHumans(mode?: TimeMode): string;
  }
}

Number.prototype.toDays = function (this: number): number {
  let seconds = this.valueOf()
  return Math.floor((((seconds % 31536000) % 86400) % 3600) / 60 / 60 / 24)
}

Number.prototype.toHours = function (this: number): number {
  let seconds = this.valueOf()
  // return Math.floor((((seconds % 31536000) % 86400) % 3600) / 60 / 60)
  return Math.floor(((seconds % 31536000) % 86400) / 3600)
}

Number.prototype.toMinutes = function (this: number): number {
  let seconds = this.valueOf()
  return Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)
}

Number.prototype.toSeconds = function (this: number): number {
  let seconds = this.valueOf()
  return Math.floor((((seconds % 31536000) % 86400) % 3600) % 60)
}

Number.prototype.timeForHumans = function (this: number, mode:TimeMode = "long"): string {
  let seconds = this.valueOf()
  var levels: Array<[number, string]> = [
    [Math.floor(seconds / 31536000), mode == 'short' ? 'yr' : 'years'],
    [Math.floor((seconds % 31536000) / 86400), mode == 'short' ? 'd' : 'days'],
    [Math.floor(((seconds % 31536000) % 86400) / 3600), mode == 'short' ? 'hr' : 'hours'],
    [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), mode == 'short' ? 'm' : 'mins'],
    [Math.floor((((seconds % 31536000) % 86400) % 3600) % 60), mode == 'short' ? 's' : 'secs'],
  ];
  var returntext: string = '';

  for (var i = 0, max = levels.length; i < max; i++) {
    if (levels[i][0] === 0) continue;
    if (i == 3 && levels[1][0] > 0) continue; // skip mins if still days left
    if (i == 4 && levels[1][0] > 0) continue; // skip secs if still days left
    if (i == 4 && levels[2][0] > 0) continue; // skip secs if still hrs left
    returntext += ' ' + levels[i][0] + (mode == 'short' ? '' : ' ') + (levels[i][0] === 1 && mode != 'short' ? levels[i][1].substr(0, levels[i][1].length - 1) : levels[i][1]);
  };
  return returntext.trim();
};
