import { useWeb3React } from '@web3-react/core'
import useSWR from 'swr'
import useGawdsContract from 'web/hooks/useGawdsContract'
import { CacheKey } from 'web/lib/cache-keys'
import useTargetChain from './useTargetChain'

export type TokenData = {
  id: number,
  tokenURI: string
}

export default function useWalletTokens(): TokenData[] {
  const { account, active, chainId } = useWeb3React()
  const contract = useGawdsContract()
  const { isOnValidChain } = useTargetChain()

  const isReady = isOnValidChain && active

  let { data: tokenCount } = useSWR(isReady ? CacheKey.tokenCount(account, chainId) : null, async function() {
    return (await contract.balanceOf(account)).toNumber()
  })

  if (!tokenCount) {
    tokenCount = 0
  }

  const { data } = useSWR(isReady && tokenCount > 0 ? `/wallet/tokens/${tokenCount}/${account}/${chainId}` : null, async function() {
    const tokens: TokenData[] = []
    for (const i of [...Array(tokenCount).keys()]) {
      const tokenId: number = (await contract.tokenOfOwnerByIndex(account, i)).toNumber()
      const token = {
        id: tokenId,
        tokenURI: (await contract.tokenURI(tokenId)).toString(),
      }
      tokens.push(token)
    }

    return tokens.sort((a, b) => a.id < b.id ? 1 : -1)
  })

  return data ||
    // stub with empty tokens once we have token count
    ([...Array(tokenCount)]).map((x) => {
      return { id: 0, tokenURI: '' }
    })
}
