import 'web/lib/string-utils'
import { StoreAction, StoreContext } from 'web/lib/store'
import { useContext, useEffect } from 'react'
import { CheckCircleIcon, StatusOnlineIcon, XIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { useWeb3React } from '@web3-react/core'
import { formatEtherscanLink } from 'web/lib/chain-utils'

export enum PolygonTxnStatus {
  Pending = 'pending',
  Submitted = 'submitted',
  Complete = 'complete',
  Error = 'error',
}

const TxnToast = ({ txn }) => {
  const { chainId } = useWeb3React()

  const {
    polygonTxns: [polygon, dispatchPolgyonTxns],
  } = useContext(StoreContext)

  const closeToast = async (txn) => {
    dispatchPolgyonTxns([StoreAction.Delete, txn])
  }

  const finished = txn.status == PolygonTxnStatus.Complete || txn.status == PolygonTxnStatus.Error

  useEffect(() => {
    if (finished) {
      setTimeout(() => {
        closeToast(txn)
      }, 5500)
    }
  }, [finished]);

  return (
    <div key={`${txn.nonce}`}
          className={classNames(`bg-gray-800 rounded-md flex p-4 mb-4 border-black border-2 transition delay-5000`)}>
      <div className="flex-grow-0 pr-4">
        {txn.status == PolygonTxnStatus.Complete && (
          <CheckCircleIcon className="text-red-500 h-8 w-8" />
        )}

        {txn.status == PolygonTxnStatus.Error && (
          <XIcon className="text-gray-500 h-8 w-8" />
        )}

        {(txn.status == PolygonTxnStatus.Pending || txn.status == PolygonTxnStatus.Submitted) && (
          <StatusOnlineIcon className="text-gray-500 h-8 w-8" />
        )}
      </div>

      <div className="flex-grow">
        {txn.command.ucfirst()} {txn.status}<br />
        {txn.hash &&
          <a href={formatEtherscanLink({ type: 'transaction', chainId: process.env.POLYGON_CHAIN_ID, hash: txn.hash })} target="_blank">View transaction</a>
        ||
          <span className="text-red-500">(ERROR, missing txn hash)</span>
        }
      </div>

      <div className="flex-grow-0">
        <button onClick={() => closeToast(txn)}>
          <XIcon className="h-4 w-4 text-gray-400" />
        </button>
      </div>
    </div>
  )
}

export default TxnToast
