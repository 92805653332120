import { Fragment, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'


import { StoreContext } from 'web/lib/store'
import WalletProviderButton, { WalletType } from './WalletProviderButton'
import useEagerConnect from 'web/hooks/useEagerConnect'


export default function ConnectWalletButton({ className = "" }) {
  useEagerConnect()

  const {
    connectWalletModal: [isOpen, dispatchModalState],
  } = useContext(StoreContext)

  function closeModal() {
    dispatchModalState(['CLOSE'])
  }

  async function openModal() {
    dispatchModalState(['OPEN'])
  }

  return (
    <>
      <div className={className}>
        <button type="button" onClick={openModal} className="primaryButton w-full md:w-auto">
          Connect Wallet
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal} open={isOpen}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-md">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-white">
                  Select a Wallet
                </Dialog.Title>

                <div className="flex flex-col space-y-2 mt-2">
                  <WalletProviderButton wallet={WalletType.MetaMask} />
                  <WalletProviderButton wallet={WalletType.WalletConnect} />
                  <WalletProviderButton wallet={WalletType.Coinbase} />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
