import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { chainNameFor } from "web/lib/chain-utils"

export default function useTargetChain() {
    const { active, chainId, error } = useWeb3React()

  // sanity check dev vs prod
  const targetChainId = !!process.env.CHAIN_ID ? process.env.CHAIN_ID : 31337
  const targetChainName = chainNameFor(targetChainId)

  return {
      isOnValidChain: active && chainId == targetChainId && !(error instanceof UnsupportedChainIdError),
      currentChainName: chainNameFor(chainId),
      targetChainName,
      targetChainId
  }

}