import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'
import { getConnector } from 'web/connectors'
import { Storage } from 'web/lib/storage'
import useEnvironment from './useEnvironment'
import { AbstractConnector } from '@web3-react/abstract-connector';

export default function useEagerConnect() {
  const { activate, active, error } = useWeb3React()
  const { log } = useEnvironment()

  const [tried, setTried] = useState(false)

  async function tryEagerConnect(connector: AbstractConnector) {
    // if (isDev) console.log('Connecting')
    setTried(true)
    const connectorUpdate = await connector.activate()
    // if (isDev) console.log("Connected", connectorUpdate)
    await activate(connector)
  }

  useEffect(() => {
    const connector = getConnector(Storage.getWallet())
    const connected = localStorage.getItem('wallet_connected')

    log('useEagerConnect', 'init', Storage.getWallet(), active, tried, connected, connector)

    if (connector && !active && !tried && connected) {
      tryEagerConnect(connector)
    }
  }, [active])

  return tried
}
