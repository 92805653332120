export class CacheKey {
  public static bloodBalance(account: string) {
    return `/bloodBalance/a${account}`
  }

  public static bloodAllowance(account: string, contractAddr: string) {
    return `/bloodAllowance/a${account}/${contractAddr}`
  }

  public static myBoons(account: string) {
    return `/myBoons/a${account}`
  }

  public static boonPrice(boonId: number) {
    return `/boons/price/a${boonId}`
  }

  public static currentCult(account: string) {
    return `/currentCult/a${account}`
  }

  public static cultLockupExpiry(account: string) {
    return `/currentCult/a${account}/lockupExpiry`
  }

  public static cultPopulation() {
    return `/cultPopulation`
  }

  public static cultSacrificeStats() {
    return `/cults/sacrifice/stats`
  }

  public static rebaserData(account: string) {
    return `/rebaser/a${account}`
  }

  public static gawchiCooldownStats() {
    return `/gawchi/stats/cooldowns`
  }

  public static gawchiStats(gawdId: number) {
    return `/gawchi/stats/${gawdId}`
  }

  public static tokenCount(account: string, chainId: number) {
    return `/wallet/tokenCount/a${account}/${chainId}`
  }

  public static totalGawdSupply(chainId: number) {
    return `/${chainId}/totalSupply`
  }

  public  static polygonBlock = '/polygonBlock'
}